* {
  padding: 0;
  margin: 0;

  box-sizing: border-box;
}
.whole_body{
  background: white;
  height: 100%;
  width: 100%;
}

/* .viewBody {
  width: 100%; */
  /* max-width: 1000px; */
  /* background-color: rgb(255, 255, 255); */
  /* background: red;
  margin-top: 10px;
  height: 100vh;
} */
.ViewHeader {
  /* background: yellow; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0rem 1rem;
}
.totalSection h4 {
  font-size: 14px !important;
}

.logoImg {
  width: 8rem;
}
.viewContent {
  padding: 0rem 1rem 1rem 1rem;
}
.companyInformation {
  margin: 0 !important;
  padding: 0 !important;
  text-align: right;
}

.companySide {
  padding: 5px 10px;
  line-height: 1.2rem;
  color: rgb(0, 0, 0);
}
.companySide h3 {
  font-size: 1.1rem;
}
.companySide p {
  color: #000000;
  font-size: 0.9rem;
}
.headerName {
  width: 100%;
  /* background-color: blue ; */
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
}
.partyInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
  padding: 5px 15px;
  /* background: rebeccapurple; */
}

.baseInfo {
  width: 45%;
}
.partyContent {
  float: right;
  width: 30%;
  line-height: 1.2rem;
  font-size: 0.99rem;
}
.eventInform {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
}
.partyContent p {
  margin-bottom: 0 !important;
}
.baseInfo h4,
.partyContent h4 {
  margin: 0 !important;
}
.contentTable {
  padding: 0 1rem;
}
.leftSide p,
.leftSide b {
  font-size: 1rem;
}
.totalSection {
  width: 100%;
  display: flex;
  padding-right: 2rem;
  justify-content: space-between;
  align-items: end;
  padding-top: 0.5rem;
}
.totalSection h3 {
  margin: 0 !important;
}
.wedding {
  display: flex;
}
#brideGroom {
  width: 40%;
}
.totalSection h4 {
  text-align: right;
}


.bankDetails_align{
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}
.bankDetails_align .bankDet {
  width: 110px;
  /* text-align: right; */
}
.bankDetails_align p b {
  text-align: left;
}
.signature_section{
  height: 164px;
  /* background: yellow; */
  text-align: end;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.signature_section_name{
  padding-top: 21px;
  /* padding-right: 20px; */
  height: 50%;
  /* padding-right: 2rem;
/* } */
}

.signature_section_manager{
  padding-right: 45px;
  /* padding-top: 12px; */
  /* background: green; */
  height: 50%;
}

.divider_sig{
  background-color: #c8cbcd;
  width: 100%;
  height: 0.5px;
}

thead.MuiTableHead-root.css-15wwp11-MuiTableHead-root{
  border-width: 2px;
}

tr.MuiTableRow-root.css-wcjgd7-MuiTableRow-root{
  border-width: 2px;
}


/* @media print {
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 12pt;
  }

  .printable-content {
    max-height: calc(100% - 3rem);
    overflow-y: auto;
  }

  .no-page-break {
    page-break-before: avoid;
    page-break-after: avoid;
  }
} */
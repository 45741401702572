.modalBody {
  padding-bottom: 3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1rem 6rem;
  /* background: rgb(236, 236, 236); */
}
#serviceSec {
  display: flex;
  align-items: flex-start;
  justify-content: center !important;
  flex-direction: column;
  column-gap: 1rem;
  width: 100%;
}
.customerInfo,
.eventInfo,
.cookInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  text-align: center;

  gap: 15px;
}
.textField {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  max-width: 1500px;
  /* width: 100%; */
}
.textField input {
  text-transform: capitalize !important;
}
.ServiceInpGrp {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 15px;
  margin-bottom: 1rem;
}
.qtyInput,
.priceInput,
.totalInput {
  width: 100px;
}

@media screen and (max-width: 991px) {
  .customerInfo,
  .eventInfo,
  .cookInfo {
    width: 100%;
  }
  h2 {
    font-size: 1.2rem;
  }

  .modalBody {
    padding: 2rem 1rem;
  }
}
@media screen and (max-width: 767px) {
  .modalBody {
    padding: 1rem 1rem;
  }
}
@media screen and (max-width: 600px) {
  .customerInfo,
  .eventInfo,
  .cookInfo {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 15px;
  }
  .button-section {
    width: 95% !important;
  }
  h2 {
    font-size: 1rem;
  }

  .qtyInput,
  .priceInput,
  .totalInput {
    width: 120px;
  }
  .modalBody {
    padding: 0rem 0rem;
  }
}
.totalamt h3 {
  font-size: 1.2rem !important;
  margin: 0 !important;
}
.amountSec {
  width: 25%;
}
h3 {
  /* text-align: justify; */
  padding: 5px 25px;
  /* font-size: 1rem; */
}
.button-section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.totalamt {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 3rem;
  padding-bottom: 5rem;
}
.sub-btn {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}
.button_add {
  background-color: black;
  box-shadow: none !important;
}
.custSugg {
  width: 100%;
  margin-bottom: 1rem;
}
@media screen and (max-width: 550px) {
  h3 {
    padding: 5px;
    font-size: 16px;
  }
  .ServiceInpGrp {
    flex-direction: column;
    align-items: flex-start;
  }
  .totalamt {
    padding: 10px;
  }
}
.footerInputs {
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  width: 100%;
  flex-direction: column-reverse;
  gap: 10px;
}

.adjusted_common_div{
  display: flex;
  align-items: center;
}

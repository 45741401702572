* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.dt-button {
  padding: 5px 10px !important;
  border-radius: 10px !important;
  border: none !important;
  background: #36a2ff !important;
  color: #fff !important;
}
.tableCell {
  padding: 10px;
}
.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #aaa;
  border-radius: 11px;
  padding: 5px;
  background-color: transparent;
  margin-left: 3px;
  width: 114% !important;
}
.dataTables_wrapper .dataTables_filter {
  float: left !important;
  text-align: left !important;
}
